.branches {
  .table {
    .table_body {
      height: auto;
      &_item {
        cursor: pointer; } } } }

.branches_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px 0;
  .new_branch_btn {
    margin-left: 30px;
    padding: 10px;
    width: 200px;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    background: #333;
    border-radius: 5px;
    transition: 0.3s;
    &:hover {
      background: #656565; } } }
