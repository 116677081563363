header {
  height: 100px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 20px -6px rgba(52, 52, 52, 0.26);
  background-color: #000; }

.header_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }
.nav {
  display: flex;
  justify-content: flex-start;
  cursor: pointer; }
.nav_link {
  padding: 0 20px;
  font-size: 15px;
  background-color: transparent;
  color: white;
  .active_menu {
    background-color: #000;
    color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 93px;
    display: none;
    a {
      color: white;
      text-decoration: none; } }
  .underline {
    width: 0;
    height: 2px;
    background: #fff;
    transition: 0.3s; }
  &:hover {
    .underline {
      width: 100%; }
    .active_menu {
      display: block;
      background-color: #000;
      &_item {
        display: block;
        padding: 10px 10px 10px 10px;
        margin-top: 10px;
        background-color: #000;
        &:hover {
          background-color: gray; } } } } }
.user {
  cursor: pointer; }





