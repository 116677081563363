body {
  background: #fdfdfd;
  font-family: 'Nunito', sans-serif; }

div {
  box-sizing: border-box; }

.container {
  width: 1440px;
  max-width: 90%;
  margin: 0 auto; }

h1, h2, h3, h4, h5, p, ul, li {
  margin: 0;
  padding: 0; }

input, textarea {
  &:focus {
    outline: none; }
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  padding: 10px; }

button {
  cursor: pointer;
  padding: 0;
  background: #fff;
  border: none;
  font-family: 'Nunito', sans-serif; }
