.login_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .login_wrapper {
    padding: 40px;
    margin: auto;
    border-radius: 15px;
    box-shadow: 0px 6px 29px -6px rgba(52, 52, 52, 0.26);

    .login_content {
      .input_group {
        width: 100%;
        position: relative;
        margin-top: 20px;
        .login_input {
          width: 100%; } }

      button {
        margin-top: 40px;
        width: 300px;
        padding: 15px;
        font-weight: 600;
        border-radius: 5px;
        border: 1px solid #f0f0f0;
        color: #fff;
        background: #333;
        transition: 0.3s;
        &:hover {
          background: #868686; } } } } }
