.new_branch_title {
  button {
    margin-top: 15px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    background: none;
    opacity: 0.6;
    transition: 0.3s;
    span {
      margin-right: 5px;
      font-size: 15px; }
    &:hover {
      opacity: 1; } } }

.new_branch_form_wrapper {
  padding-top: 35px; }

.form_row_address {
  display: flex;
  align-items: center;
  .row_content {
    display: flex;
    align-items: center;
    .input_group {
      display: flex;
      flex-direction: column;
      &:not(:first-child) {
        margin-left: 25px; } } } }

.form_row_type {
  display: flex;
  margin-top: 40px;
  .row_content {
    display: flex;
    flex-direction: column;
    .input_group {
      label {
        margin-left: 10px; }
      &:not(:first-child) {
        margin-top: 5px; } } } }

.form_row_schedule {
  display: flex;
  margin-top: 40px;
  .row_content {
    display: flex;
    align-items: center;
    .custom_input {
      padding: 6px;
      width: 170px;
      p {
        font-size: 14px; } }
    .separator {
      margin: 0 10px; }
    .time_input_group {
      display: flex;
      align-items: center;
      margin-left: 40px;
      input {
        width: 100px; } }
    .checkbox_input_group {
      margin-left: 20px;
      label {
        font-size: 15px;
        margin-left: 5px; } } } }

.form_row_latitude {
  display: flex;
  margin-top: 40px; }

.form_row_longitude {
  display: flex;
  margin-top: 40px; }

.form_row_phone {
  display: flex;
  margin-top: 40px;
  .row_content {
    display: flex;
    align-items: center;
    .input_group {
      margin: 0 20px 0 0; } } }

.form_row_name {
  display: flex;
  margin-top: 40px; }

.row_title {
  min-width: 200px; }


.buttonSaveContainer {
  margin: 20px 0 0 0;
  justify-content: center;
  display: flex; }

.buttonBack {
  display: flex;
  align-items: center; }
