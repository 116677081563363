.labelEmptyPriceList, .loader {
  margin: 50px 0;
  color: red;
  text-align: center; }

.table {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  max-height: 530px;
  overflow-y: auto;
  &_head {
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    &_title {
      width: 25%;
      text-align: center; } }
  &_body {
    border-top: 1px solid #eee;
    overflow: auto;
    &_item {
      width: 100%;
      padding: 15px 20px;
      display: flex;
      justify-content: space-between;
      transition: 0.3s;
      align-items: center;
      &_title, &_cash, &_checkingAccount {
        width: 25%;
        text-align: center; }
      &:hover {
        background: #eaeaea; } } } }

.priceListControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 50px 0; }

.buttons_group {
  display: flex;
  align-items: center;
  button {
    padding: 15px;
    &:not(:first-child) {
      margin-left: 10px; } } }

.select_group {
  display: flex;
  align-items: center;
  &_item:not(:first-child) {
    margin: 0 0 0 20px; } }

.selectCategory {
  margin: 0 0 0 20px; }

.actionTools {
  width: 25%;
  text-align: center; }
